import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

export default function Navbar() {
  const homeUrl = process.env.REACT_APP_HOME_URL || "/";

  return (
    <nav className="navigation">
      <nav className="bg-white border-gray-200">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
          <Link to={homeUrl} className="flex items-center">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6f/Octicons-terminal.svg"
              className="h-8 mr-3"
              alt="Flowbite Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-gray-900">
              Thinesh's Tech Blog
            </span>
          </Link>
        </div>
      </nav>
      <nav className="bg-gray-50">
        <div className="max-w-screen-xl px-4 py-3 mx-auto">
          <div className="flex items-center">
            <ul className="flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm">
              <li>
                <Link
                  to={homeUrl}
                  className="text-gray-900 hover:underline"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-900 hover:underline">
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </nav>
  );
}
