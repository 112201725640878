import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatDate } from "../utils/dateFormatter"; // Import the utility function

interface Post {
  id: string;
  title: string;
  content: string;
  url: string;
  hashtags: string[];
  timestamp: string;
}

const PostPage: React.FC = () => {
  const { url } = useParams<{ url: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get<Post>(
          `${apiUrl}/post/${url}`
        );
        setPost(response.data);
      } catch (err) {
        setError("Failed to fetch post");
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [url]);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500 py-4">{error}</div>;

  return (
    <div className="p-4 max-w-screen-lg mx-auto">
      <h1 className="text-4xl font-bold mb-4 text-gray-900">{post!.title}</h1>
      <p className="text-gray-500 mb-4">
        Published on: {formatDate(post!.timestamp)}
      </p>
      <div
        className="text-gray-700 mb-6"
        dangerouslySetInnerHTML={{ __html: post!.content }}
      />
      {post!.hashtags.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-6">
          {post!.hashtags.map((tag, index) => (
            <span
              key={index}
              className="bg-gray-200 text-gray-800 rounded-full px-3 py-1 text-sm"
            >
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostPage;
