import React from "react";
import { FaLinkedin, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const AboutPage: React.FC = () => {
  return (
    <div className="p-4 max-w-screen-lg mx-auto"> {/* Center and constrain width */}
      <div className="flex">
        {/* Left Section */}
        <div className="w-2/3 p-8">
          <h1 className="text-4xl font-bold mb-4">About Me</h1>
          <p className="text-lg text-gray-700 mb-4">
            Hello! I'm Thinesh, and this is my tech blog where I share insights
            and updates about technology, software development, and more. I have a
            passion for learning and exploring new tech trends. Feel free to
            connect with me and follow along as I dive into various tech topics
            and projects.
          </p>
        </div>

        {/* Right Section */}
        <div className="w-1/3 p-8">
          <h2 className="text-2xl font-bold mb-4">Contact</h2>
          <div className="flex flex-col space-y-4">
            <a
              href="https://www.linkedin.com/in/thineshcv/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-700 hover:text-gray-900"
            >
              <FaLinkedin className="mr-2 text-gray-500" />
              linkedin.com/in/thineshcv/
            </a>
            <a
              href="https://x.com/thineshcv"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-700 hover:text-gray-900"
            >
              <FaXTwitter className="mr-2 text-gray-500" />
              x.com/thineshcv
            </a>
            <a
              href="mailto:thineshstechblog@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-700 hover:text-gray-900"
            >
              <FaEnvelope className="mr-2 text-gray-500" />
              thineshstechblog@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
