import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white text-gray-900 py-4 text-center border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto px-4">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Thinesh's Tech Blog. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
}
