import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown"; // Import ReactMarkdown
import { formatDate } from "../utils/dateFormatter";

interface Post {
  id: string;
  title: string;
  content: string;
  url: string;
  hashtags: string[];
  timestamp: string; // Assuming the timestamp is in ISO string format
}

// Function to detect if content is HTML
const isHTML = (content: string) => /<\/?[a-z][\s\S]*>/i.test(content);

const BlogLandingPage: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get<Post[]>(`${apiUrl}/posts`);
        setPosts(response.data);
      } catch (err) {
        setError("Failed to fetch posts");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500 py-4">{error}</div>;

  return (
    <div className="p-4 max-w-screen-xl mx-auto">
      {posts.length === 0 ? (
        <p className="text-center text-gray-500">No posts available</p>
      ) : (
        <ul className="space-y-6">
          {posts.map((post) => (
            <li
              key={post.id}
              className="relative bg-white shadow-md rounded-lg p-6 border border-gray-200 max-w-screen-lg mx-auto"
            >
              <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                <Link
                  to={`/post/${post.url}`}
                  className="text-gray-900 no-underline"
                >
                  {post.title}
                </Link>
              </h2>
              {/* Render content based on its type */}
              <div className="text-gray-700 mb-4">
                {isHTML(post.content) ? (
                  <div dangerouslySetInnerHTML={{ __html: post.content }} />
                ) : (
                  <ReactMarkdown>{post.content}</ReactMarkdown>
                )}
              </div>
              <div className="flex flex-wrap gap-2 mb-4">
                {post.hashtags.map((tag) => (
                  <span
                    key={tag}
                    className="bg-gray-200 text-gray-800 rounded-full px-3 py-1 text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <p className="absolute bottom-4 right-4 text-gray-500 text-sm">
                {formatDate(post.timestamp)}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BlogLandingPage;
